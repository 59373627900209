<template>
  <v-sheet elevation="3" class="pa-3">
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field label="Поиск" :value="tableParams.title" clearable @input="$emit('change-title', $event)" />
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  props: {
    tableParams: {
      type: Object,
      required: true
    }
  }
};
</script>
