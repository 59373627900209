<template>
  <div>
    <h2 class="mb-4">Криптовалюты</h2>

    <crypto-filter class="mb-5" :table-params="tableParams" @change-title="updateTitle" />

    <data-table
      url="cryptocurrencies"
      item-key="code"
      clickable-rows
      :headers="headers"
      :table-params="tableParams"
      @click:row="editCryptocurrency"
    >
      <template v-slot:[`item.code`]="{ item }">
        <div class="d-flex align-center py-2">
          <div>
            <v-img class="rounded-circle" :src="item.logo" width="44" height="44" />
          </div>

          <div class="ml-4">
            {{ item.code }}
          </div>
        </div>
      </template>

      <template v-slot:[`item.isActive`]="{ item }">
        <div>{{ item.isActive | yesNo }}</div>
      </template>
    </data-table>
  </div>
</template>

<script>
import CryptoFilter from "~/components/crypto/filter";

export default {
  data() {
    return {
      headers: [
        { text: "Код", value: "code", sortable: false },
        { text: "Название", value: "name", sortable: false },
        { text: "Активна", value: "isActive", sortable: false }
      ],
      tableParams: {
        title: "",
        sort: { by: "title" }
      }
    };
  },
  methods: {
    editCryptocurrency(cryptocurrency) {
      this.$router.push({ name: "crypto-code", params: { code: cryptocurrency.code } });
    },
    updateTitle(value) {
      this.tableParams.title = value;
    }
  },
  components: {
    CryptoFilter
  }
};
</script>
